import React, { Component } from 'react';
import iconTuboRedondo from '../../images/tubos/icon_tubo_redondo.png';
import iconTuboQuadrado from '../../images/tubos/icon_tubo_quadrado.png';

import './Tubos.scss'

class TubosComCostura extends Component {

    render() {
        return (
            <section className="product-specifications">
                <div className="container">
                    <div className="box">
                        <h3>ESPECIFICAÇÕES</h3>
                        <div className="specification-list">
                            <div className="specification-list-item left">
                                <p className="title">Tubos redondos</p>
                                <div className="icon-wrapper">
                                    <img src={iconTuboRedondo} alt="" className="icon"/>
                                </div>
                                <div className="text">
                                    <p>Tipos de aço: 201, 202, 304/L, 316/L, 409, 439, 444, entre outros.</p>
                                    <p>Normas: ASTM A-554, ASTM A-269, ASTM A-268, ASTM A-249, ASTM A-270, ASTM A-312, ASTM A-358 e ASTM A-778</p>
                                    <p>Acabamentos: Decapado, polido e escovado</p>
                                    <p>Diâmetros: De 8,00 MM a 24" em estoque, outras medidas sob consulta.</p>
                                </div>
                            </div>
                            <div className="specification-list-item left right">
                                <p className="title">Tubos quadrados e retangulares</p>
                                <div className="icon-wrapper">
                                    <img src={iconTuboQuadrado} alt="" className="icon"/>
                                </div>
                                <div className="text">
                                    <p>Tipos de aço: 201, 202, 304/L, entre outros.</p>
                                    <p>Norma: ASTM A-554</p>
                                    <p>Acabamentos: Decapado, polido e escovado</p>
                                    <p>Diâmetros: A partir de quadrado de 10,00 MM, com diversas variações.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default TubosComCostura;
