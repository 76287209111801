import React, { Component } from 'react';
import iconBarraInoxRedonda from '../../images/barra_inox_redonda.png';
import iconBarraInoxSextavada from '../../images/barra_inox_sextavada.png';
import iconBarraInoxQuadrada from '../../images/barra_inox_quadrada.png';
import iconBarraInoxRetangular from '../../images/barra_inox_retangular.png';
import './Barras.scss'

export default class Barras410 extends Component {

    render() {
        return (
            <section className="product-specifications">
                <div className="container">
                    <div className="box">
                        <h3>ESPECIFICAÇÕES</h3>
                        <div className="specification-list">
                            <div className="specification-list-item">
                                <div className="icon-wrapper">
                                    <img src={iconBarraInoxRedonda} alt="" className="icon"/>
                                </div>
                                <div className="text">
                                    <p>A <span>Barra de Inox Redonda</span> Possui os acabamentos: descascadas,
                                        torneadas ou retificadas</p>
                                    <p>Bitolas: 2,00MM a 762,00MM</p>
                                    <p>Tipos de aço: 410, entre outros</p>
                                </div>
                            </div>
                            <div className="specification-list-item">
                                <div className="icon-wrapper">
                                    <img src={iconBarraInoxSextavada} alt="" className="icon"/>
                                </div>
                                <div className="text">
                                    <p>A <span>Barra de Inox Sextavada</span> é laminada com perfil sextavado
                                    </p>
                                    <p>Bitolas: 1/4" a 2"</p>
                                    <p>Tipos de aço: 410, entre outros</p>
                                </div>
                            </div>
                            <div className="specification-list-item">
                                <div className="icon-wrapper">
                                    <img src={iconBarraInoxQuadrada} alt="" className="icon"/>
                                </div>
                                <div className="text">
                                    <p>A <span>Barra de Inox Quadrada</span> é laminada com perfil quadrado</p>
                                    <p>Bitolas: 3/16" a 2"</p>
                                    <p>Tipos de aço: 410, entre outros</p>
                                </div>
                            </div>
                            <div className="specification-list-item">
                                <div className="icon-wrapper">
                                    <img src={iconBarraInoxRetangular} alt="" className="icon"/>
                                </div>
                                <div className="text">
                                    <p>A <span>Barra de Inox Retangular</span> é laminada (chata) ou relaminada (cortada de chapa) com perfil retangular</p>
                                    <p>Espessura: 1/8" a 2"</p>
                                    <p>Largura: 1/2" a 4"</p>
                                    <p>Tipos de aço: 410, entre outros</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
