import React, { Component } from 'react';
import ContactForm from './ContactForm';
import { baseClass } from './ProductSpecifications';

class Contact extends Component {

    render() {

        const hotsiste = process.env.REACT_APP_HOTSITE

        return (
            <section className={`contact ${baseClass} ${hotsiste}`} id="contato">
                <div className="container">
                    <div className="box">
                        <div className="title-wrapper">
                            <h3 className="title">Contatos</h3>
                        </div>
                        <p className="description">
                            Se interessou pelo nosso produto?
                            Entre em contato conosco!
                            Preencha os campos abaixo e entraremos em contato com você.
                        </p>

                        <ContactForm/>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;
