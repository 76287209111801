import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import PropTypes from 'prop-types';
import logo from '../images/logo_blackwhite.png';
import iconEntrega from '../images/icon_entrega.png';
import iconEstoque from '../images/icon_estoque.png';
import iconPreco from '../images/icon_preco.png';
import { getProduct } from '../products';
import { baseClass } from './ProductSpecifications';

class Header extends Component {

    static propTypes = {
        onBudgetClick: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            product: getProduct()
        };
    }

    closeMenu = () => {
        this.setState({menuOpen: false});
    };

    handleBudgetClick = (e) => {
        if (this.props.onBudgetClick) {
            this.props.onBudgetClick(e);
        }

        this.closeMenu();
    };

    render() {

        const product = this.state.product;

        return (
            <div className={`header ${baseClass} ${process.env.REACT_APP_HOTSITE}`} id="home">

                <nav className={`menu ${this.state.menuOpen ? 'open' : ''}`}>
                    <div className="container">
                        <ul>
                            <li><AnchorLink href="#home" onClick={() => this.closeMenu()}>Início</AnchorLink></li>
                            <li><AnchorLink href="#empresa" onClick={() => this.closeMenu()}>A empresa</AnchorLink></li>
                            <li><a href="http://www.jatinox.com.br/cotacao" target="_blank" onClick={this.handleBudgetClick}>Orçamento</a></li>
                            <li><AnchorLink href="#contato" onClick={() => this.closeMenu()}>Contato</AnchorLink></li>
                        </ul>
                    </div>
                </nav>

                <div className="container">

                    <div className="logo hidden-mobile">
                        <img src={logo} alt=""/>
                    </div>

                    <div className={`product-image ${baseClass} ${process.env.REACT_APP_HOTSITE}`} />

                    <button className={`nav-toggle hidden-desktop ${this.state.menuOpen ? 'open' : ''}`} onClick={() => this.setState({menuOpen: ! this.state.menuOpen})} />

                    <div className="wrapper-box">
                        <section className={`product-info-box ${baseClass} ${process.env.REACT_APP_HOTSITE}`}>
                            <h1 dangerouslySetInnerHTML={{__html: product.title_html}} className={baseClass + ' ' + process.env.REACT_APP_HOTSITE} />
                            <div className="box-icons">
                                <img src={iconEstoque} alt=""/>
                                <img src={iconPreco} alt=""/>
                                <img src={iconEntrega} alt=""/>
                            </div>
                            <p>
                                {product.description}
                                <span className="wrapper-btn-orcamento">
                                    <a href="http://www.jatinox.com.br/cotacao" target="_blank" className="btn-orcamento">SOLICITE UM ORÇAMENTO</a>
                                </span>
                            </p>
                        </section>

                        <section className="contact-box">
                            <p className="title">TELEFONES:</p>
                            <div>
                                <p>São Paulo:<br/>(11) 2172-0405 | 2060-0405</p>
                                <p className="contact-left">Indaiatuba:<br/>(19) 3115-4300</p>
                                <p className="contact-right">Paraná:<br/>(41) 3245-5111</p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
